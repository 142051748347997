import React from "react";
import { OutboundLink } from "gatsby-plugin-google-analytics";

import { FaGlobe } from "@react-icons/all-files/fa/FaGlobe";

import Head from "../../components/head.js";
import Layout from "../../components/layout";

const Julia = () => {
  return (
    <Layout>
      <Head title="Julia Butterfly Hill" />
      <h3>Julia Butterfly Hill</h3>
      <h4>
        <FaGlobe style={{ marginRight: 7, paddingTop: 5 }} />
        Berkeley, CA
      </h4>
      <p>
        <OutboundLink href="https://en.wikipedia.org/wiki/Julia_Butterfly_Hill">
          Wikipedia
        </OutboundLink>
      </p>
      <p>
        <OutboundLink href="https://www.juliabutterflyhill.com/a-love-poem/">
          Website
        </OutboundLink>
      </p>

      <p>
        In our culture, we are manifesting an addiction to comfortability. We
        are birthing an addict society. I went through a time in my life where I
        was a major drug and alcohol addict. And so I know, on a very real
        level, what addiction is. As a society, we are being birthed into an
        addiction culture, where you need consumerism, you need comfortability.
        And you go through the same withdrawals, all the same things you go
        through as a drug addict or alcoholic. I see even the most conscious
        among us making the most unconscious decisions based on our addiction to
        comfortability.
      </p>

      <p>
        I love using the example of the caterpillar becoming the butterfly: the
        caterpillar has a pretty cool life. It’s chill. It’s safe; it doesn’t
        have too many threats, it’s usually camouflaged. Yet, the caterpillar
        has this deeper calling that defies description within itself, and it
        tells the caterpillar, there’s something more for you. In many ways, we
        are that character. The caterpillar has to trust it can follow this
        great unknown, this great mystery.
      </p>

      <p>
        And fear keeps us from trusting in that great mystery. To me, the divine
        is the great mystery. Every time I take a breath and I’m amazed that I
        can breathe! The caterpillar has to liquefy, and the caterpillar even
        has to have its head pop off in order to transform into a butterfly. So
        it’s hard work! We are afraid of what’s inside ourselves. But the
        caterpillar knows. The caterpillar knows how to trust. It knows that
        even the cocoon can become too comfortable.
      </p>

      <p>
        The caterpillar knows that it has to push through the barriers of
        comfortability. Once the caterpillar transforms, it takes a moment of
        grace to flap its wings. And then it takes a leap to take its first
        flight. I think that our fear consistently keeps us from that process;
        it’s what keeps us from knowing the magic of this space. We say to
        ourselves, that edge looks a bit too scary, I’m just gong to chill here.
      </p>

      <h3>A Love Poem</h3>
      <pre>
        My heart longs to write you Love poems like Rumi, like Hafiz. But my
        creativity and Divine Bliss connection seems much too limited. But what
        i do know is that if i had a way to wave a magic wand with words, you
        would feel more seen, more appreciated, more loved, more cared for than
        you ever have before. Not because i want to be a comparison, but because
        you inspire my heart, my soul, my very being to long to caress your
        soul, your heart, your body, your being with such bliss and love that
        you experience yourself as the total magical, amazing, Divine gift with
        which i experience you. You inspire me to want to be the person that has
        you experience yourself as this beautiful, Sacred. oh, so Sacred altar
        to which i bow in reverence and gratitude and purest loving devotion to.
        Because i do.
      </pre>
      <p>
        <a href="https://www.juliabutterflyhill.com/a-love-poem/">poem link</a>
      </p>
      <pre>
        The loss of nature is the loss of the sacred. The loss of the sacred is
        the loss of our soul. In our search for economic value, we need to place
        value on our soul – because our soul is our connection to nature, to
        that Creation source. When we see a loss of the sacred, when we see the
        loss of our planet, when we see a loss of life, we are seeing a loss of
        a piece of our souls. And I think that’s why environmental issues, when
        they are extreme, speak so deeply to us, because they reach right into
        the depths of our souls and shake us at the very foundations of who we
        are.
      </pre>
      <p>
        <a href="https://www.juliabutterflyhill.com/nature-is-our-soul/">
          poem link
        </a>
      </p>
    </Layout>
  );
};

export default Julia;
